var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "listar-corretor pr-4" }, [
    _c(
      "div",
      { staticClass: "m-1 mr-4 px-0" },
      [
        _c("breadcrumb", {
          attrs: { title: "Corretores", actualPage: "Lista de Corretores" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c(
          "vs-table",
          {
            staticClass: "table-list",
            attrs: {
              sst: true,
              search: "",
              noDataText: "Nenhum corretor encontrado",
              data: _vm.listaCorretores || [],
              hoverFlat: true
            },
            on: {
              search: _vm.handleSearch,
              "change-page": _vm.handleChangePage
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var data = ref.data
                  return _vm._l(data, function(tr, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr, attrs: { data: tr } },
                      [
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-center",
                            attrs: { data: data[indextr].BrokerDocument }
                          },
                          [
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(_vm._s(data[indextr].BrokerDocument))
                            ])
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-center",
                            attrs: { data: data[indextr].BrokerName }
                          },
                          [
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(_vm._s(data[indextr].BrokerName))
                            ])
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-center",
                            attrs: { data: data[indextr].BrokerId }
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary", type: "filled" },
                                on: {
                                  click: function($event) {
                                    return _vm.login(data[indextr])
                                  }
                                }
                              },
                              [_vm._v("Efetuar login")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                }
              }
            ])
          },
          [
            _c(
              "template",
              { slot: "thead" },
              [
                _c("vs-th", { staticClass: "text-center" }, [_vm._v("CNPJ")]),
                _c("vs-th", { staticClass: "text-center" }, [_vm._v("Nome")]),
                _c("vs-th")
              ],
              1
            )
          ],
          2
        ),
        _c("vs-pagination", {
          staticClass: "mt-5",
          attrs: { total: _vm.numberOfPages },
          model: {
            value: _vm.currentPage,
            callback: function($$v) {
              _vm.currentPage = $$v
            },
            expression: "currentPage"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }